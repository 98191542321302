import React from 'react';

import './styles.scss';

import DownloadArrow from './i/download-arrow.png';

const ButtonDownload = () => (
  
  <a href="#" className="download-link">
    <button className="download-button">
      Add to Chrome <img src={DownloadArrow} alt="Add to Chrome" />
    </button>
  </a>
)
export default ButtonDownload;