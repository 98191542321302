import { useRef } from 'react';

import { Graphics, useTick } from '@inlet/react-pixi';
import { forwardRef, useCallback, useEffect, useState } from 'react';
import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { useMount } from '@react-hooks-library/core';

const shuffle = (array) => {
    let currentIndex = array.length,
        randomIndex;

    // While there remain elements to shuffle...
    while (currentIndex !== 0) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [ array[currentIndex], array[randomIndex] ] = [ array[randomIndex], array[currentIndex] ];
    }

    return array;
};

let blockTL, blockScrubTL;
let timeoutID;

const BlockMask = forwardRef((props, ref) => {
    useMount(() => {
        console.log('I have been mounted');
        blockTL = gsap.timeline();
        blockScrubTL = gsap.timeline({
            scrollTrigger: {
                trigger: '.scroll-start',
                start: 'top top',
                endTrigger: '.scroll-end',
                end: 'center center',
                scrub: 0.2,
                // markers: {
                //     startColor: 'white',
                //     endColor: 'white',
                //     fontSize: '20px',
                //     fontWeight: 'bold',
                //     indent: 20
                // }
            }
        });
        ScrollTrigger.addEventListener('scrollStart', () => {
            // console.log('scrolling');
            scrolling.current = true;
        });
        ScrollTrigger.addEventListener('scrollEnd', () => {
            // console.log('not scrolling');
            scrolling.current = false;
        });

        clearTimeout(timeoutID);
        timeoutID = setTimeout(() => {
            setInitAnimate(false);
        }, 2000);
    });

    const { stageWidth, stageHeight, maxBlockWidth, minBlockCount } = props;
    const [ blockWidth, setBlockWidth ] = useState(0);
    const [ xBlocks, setXBlocks ] = useState(0);
    const [ yBlocks, setYBlocks ] = useState(4);

    const [ storedAutoRects, setStoredAutoRects ] = useState([]);
    const [ storedScrubRects, setStoredScrubRects ] = useState([]);
    const scrolling = useRef(false);

    const [ timeOffset, setTimeOffset ] = useState(0);
    const [ initAnimate, setInitAnimate ] = useState(true);

    useTick((delta) => {
        if (scrolling.current || initAnimate) {
            setTimeOffset(delta);
        }
    });

    useEffect(
        () => {
            const maxX = Math.max(minBlockCount, Math.ceil(stageWidth / maxBlockWidth));
            const maxY = Math.ceil(stageHeight / maxBlockWidth) + 4;
            const calcW = stageWidth / maxX;
            setBlockWidth(calcW);
            setXBlocks(maxX);
            setYBlocks(maxY);

            clearTimeout(timeoutID);
            setInitAnimate(true);
            timeoutID = setTimeout(() => {
                setInitAnimate(false);
            }, 2000);
        },
        [ stageWidth, stageHeight, maxBlockWidth ]
    );

    useEffect(
        () => {
            let rectArray = [];
            let yIndex = -1;
            for (let i = 0; i < xBlocks * yBlocks; i++) {
                if (i % xBlocks === 0) yIndex++;
                const anchorSide = Math.ceil(Math.random() * 2) % 2;
                rectArray.push({ x: i % xBlocks, y: yIndex, sizePerc: 0, anchor: anchorSide });
            }
            rectArray = shuffle(rectArray);
            const slicePoint = Math.floor(rectArray.length / 4);
            setStoredScrubRects(rectArray.slice(slicePoint));
            setStoredAutoRects(rectArray.slice(0, slicePoint));
        },
        [ xBlocks, yBlocks ]
    );

    useEffect(
        () => {
            if (storedAutoRects.length <= 0 || storedScrubRects.length <= 0) return;
            blockTL.clear();
            blockScrubTL.clear();
            blockTL.fromTo(storedAutoRects, { sizePerc: 0 }, { sizePerc: 1, ease: 'power2.inOut' });
            const fract = Math.floor(storedScrubRects.length / 3);
            const layer1 = storedScrubRects.slice(0, fract);
            const layer2 = storedScrubRects.slice(fract, fract * 2);
            const layer3 = storedScrubRects.slice(fract * 2);

            blockScrubTL.fromTo(
                layer1,
                { sizePerc: 0 },
                {
                    sizePerc: 1,
                    ease: 'power2.inOut'
                }
            );
            blockScrubTL.fromTo(
                layer2,
                { sizePerc: 0 },
                {
                    sizePerc: 1,
                    ease: 'power2.inOut'
                }
            );
            blockScrubTL.fromTo(
                layer3,
                { sizePerc: 0 },
                {
                    sizePerc: 1,
                    ease: 'power2.inOut'
                }
            );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [ storedAutoRects, storedScrubRects ]
    );

    const drawBlockLogic = ({ x, y, sizePerc, anchor }, g) => {
        g.drawRect(
            blockWidth * x + blockWidth * (1 - sizePerc) * anchor,
            blockWidth * y,
            blockWidth * sizePerc,
            blockWidth * sizePerc
        );
    };

    const drawBlocks = useCallback(
        (g) => {
            g.clear();
            g.beginFill(0xffffff);

            for (var i = 0; i < storedAutoRects.length; i++) {
                drawBlockLogic(storedAutoRects[i], g);
            }
            for (var j = 0; j < storedScrubRects.length; j++) {
                drawBlockLogic(storedScrubRects[j], g);
            }

            g.endFill();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [ storedAutoRects, storedScrubRects, drawBlockLogic, timeOffset ]
    );

    return <Graphics draw={drawBlocks} ref={ref} />;
});

export default BlockMask;
