import React from 'react';
import { Fade } from 'react-awesome-reveal';
import { gsap } from 'gsap';
import { useMount } from '@react-hooks-library/core';
import useInView from 'react-cool-inview';
import './styles.scss';

import { ReactComponent as StandardImg } from './i/svgs/standard.svg';
import { ReactComponent as GifImg } from './i/svgs/gif.svg';
import { ReactComponent as CloudImg } from './i/svgs/cloud.svg';

let standardTL, gifTL, cloudTL, fadeTL;
let furthest = 0;

const SectionTips = () => {
    useMount(() => {
        standardTL = gsap.timeline({
            paused: true
        });
        standardTL.add([
            gsap.from('.standard-icon', {
                duration: 0.5,
                scale: 0,
                ease: 'back.out(0.5)',
                transformOrigin: 'center center'
            }),
            gsap.from('.arrow-top-left', {
                duration: 1,
                scale: 0,
                ease: 'back.out(5)',
                transformOrigin: 'bottom right'
            }),
            gsap.from('.arrow-top-right', {
                duration: 1,
                scale: 0,
                ease: 'back.out(5)',
                transformOrigin: 'bottom left'
            }),
            gsap.from('.arrow-bottom-left', {
                duration: 1,
                scale: 0,
                ease: 'back.out(5)',
                transformOrigin: 'top right'
            }),
            gsap.from('.arrow-bottom-right', {
                duration: 1,
                scale: 0,
                ease: 'back.out(5)',
                transformOrigin: 'top left'
            }),
            gsap.from('.stem, .arm', { duration: 0.5, drawSVG: '0%' })
        ]);
        gifTL = gsap.timeline({
            paused: true
        });
        gifTL.add([
            gsap.from('.cat', {
                duration: 0.5,
                scale: 0,
                ease: 'back.out(2)',
                transformOrigin: 'center center'
            }),
            gsap.from('.crosses', { duration: 1, scale: 0, ease: 'back.out(6)', transformOrigin: 'center center' }),
            gsap.from('.crosses line', { duration: 1, drawSVG: '50% 50%' })
        ]);
        cloudTL = gsap.timeline({
            paused: true
        });
        cloudTL.add([
            gsap.from('.cloud', {
                duration: 0.5,
                scale: 0,
                ease: 'back.out(2)',
                transformOrigin: 'center center'
            }),
            gsap.from('.check', { duration: 1, scale: 0, ease: 'back.out(6) ', transformOrigin: 'center center' }),
            gsap.from('.check polyline', { duration: 1, drawSVG: '0%' })
        ]);

        fadeTL = gsap.timeline({
            paused: true
        });
        fadeTL.from('.tip-1', {
            opacity: 0
        });
        fadeTL.call(() => {
            standardTL.play();
        });
        fadeTL.addLabel('tip-1-visible');
        fadeTL.from('.tip-2', {
            opacity: 0
        });
        fadeTL.call(() => {
            gifTL.play();
        });
        fadeTL.addLabel('tip-2-visible');
        fadeTL.from('.tip-3', {
            opacity: 0
        });
        fadeTL.call(() => {
            cloudTL.play();
        });
        fadeTL.addLabel('tip-3-visible');
    });

    const doViewCheck = (level) => {
        if (level <= furthest) return;
        if (level === 1) {
            fadeTL.tweenTo('tip-1-visible');
        } else if (level === 2) {
            fadeTL.tweenTo('tip-2-visible');
        } else if (level === 3) {
            fadeTL.tweenTo('tip-3-visible');
        }

        furthest = Math.min(level, 3);
    };

    const { observe: tip1Observe } = useInView({
        onEnter: ({ unobserve }) => {
            unobserve();
            doViewCheck(1);
        },
        threshold: 1
    });
    const { observe: tip2Observe } = useInView({
        onEnter: ({ unobserve }) => {
            unobserve();
            doViewCheck(2);
        },
        threshold: 1
    });
    const { observe: tip3Observe } = useInView({
        onEnter: ({ unobserve }) => {
            unobserve();
            doViewCheck(3);
        },
        threshold: 1
    });
    return (
        <div className="section-block tips">
            <Fade triggerOnce delay={200}>
                <h3 className="section-title">Tips to reduce your footprint</h3>
            </Fade>
            <div className="tips-wrapper">
                <div className="tip-1 fade-tip" ref={tip1Observe}>
                    <StandardImg />
                    <h4>Stream in SD</h4>
                    <p>Lower the resolution of your videos to keep the strain on data centres&nbsp;low.</p>
                </div>
                <div className="tip-2 fade-tip" ref={tip2Observe}>
                    <GifImg />
                    <h4>Ditch the GIFS</h4>
                    <p>Emails with GIFs may be fun, but the bigger the email, the bigger your&nbsp;footprint.</p>
                </div>
                <div className="tip-3 fade-tip" ref={tip3Observe}>
                    <CloudImg />
                    <h4>Choose a Conscious Cloud</h4>
                    <p>If you use cloud based storage, check to see if your provider runs on renewable&nbsp;energy.</p>
                </div>
            </div>
        </div>
    );
};
export default SectionTips;
