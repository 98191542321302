import React from "react";
import Slider from "react-slick";
import { Fade } from 'react-awesome-reveal';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./styles.scss";

const SectionWhyItMatters = () => {
    var settings = {
        dots: true,
        infinite: true,
        //speed: 1200,
        autoplaySpeed: 5000,
        autoplay: true,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    return (
        <div className="section-block why-it-matters">
            <div className="section-2-copy-container">
                <div className="section-2-copy">
                    <Fade triggerOnce delay={200}>
                    <h3 className="section-title">Why it matters</h3>
                    <p>
                        Everything we do online generates carbon emissions — from the
                        devices used to the energy required to send information through
                        network infrastructure and data centres. And even though the
                        individuals' footprint can be quite small, when you add up the
                        4.66 billion Internet users, it's a huge driver of
                        climate&nbsp;change.
                    </p>
                    </Fade>
                </div>
            </div>
           
            <div className="section-2-slide">
               
                <div className="slider-wrapper">
               
                    <Slider {...settings}>
                        <div className="each-slide slide-1">
                            <div className="slide-contain">
                                By <span className="bright-green">2025</span> internet emissions<br />are expected to increase&nbsp;by <span className="header-font">200<span className="bright-green">%</span></span>
                            </div>
                        </div>
                        <div className="each-slide slide-2">
                            <div className="slide-contain">
                                The internet is a <span className="bright-green">bigger<br />polluter</span> than the <span className="header-font"><span className="airline">airline</span><br /><span className="industry">industry</span></span>
                            </div>
                        </div>
                        <div className="each-slide slide-3">
                        <div className="slide-contain"> 
                            <span className="bright-green">One hour</span> of Netflix is<br />equivalent to driving <span className="header-font"><span className="bright-green">4</span>KM</span>
                            </div>
                        </div>
                        <div className="each-slide slide-4">
                            <div className="slide-contain"> 
                        An hour of Zoom can emit up to <span className="header-font">250<span className="bright-green">g</span></span> <span className="ofco2">of CO2</span>
                        </div>
                        </div>
                    
                    </Slider>
                   
                </div>
             
            </div>
        </div>
    )
};
export default SectionWhyItMatters;