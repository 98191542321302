import React from "react";
import "./styles.scss";

import SvgInstagram from "./SvgInstagram/SvgInstagram";
import SvgFacebook from "./SvgFacebook/SvgFacebook";
import SvgTwitter from './SvgTwitter/SvgTwitter';
import treeCanadaLogo from './i/tree-canada-logo.png';

const Footer = () => {

    return (
        <div className="section-block footer">
            <div className="footer-content">
                <h3 className="section-title">About Us</h3>
                <p>Tree Canada is the only national non-profit organization dedicated to planting and nurturing trees in rural and urban environments. Through our programs, research and engagement efforts, we have helped restore tree cover in areas hit by natural disasters, guided communities in managing their urban forests, supported over 700 schoolyard greening projects and organized urban forest conferences. To date, with our community partners and sponsors, we have planted more than 83 million trees.</p>
            </div>
            <div className="tree-logo">
                <img src={treeCanadaLogo} alt="tree canada logo" />
            </div>
            <div className="social">
               <SvgInstagram link="https://www.instagram.com/treecanada/" />
               <SvgFacebook link="https://www.facebook.com/TreeCanada/" />
               <SvgTwitter link="https://twitter.com/treecanada" />
            </div>
            
           
        </div>
    )
};
export default Footer;