import React from 'react';
import Helmet from 'react-helmet';

const MetaInfo = (props) => {

    return (
        <Helmet>
            <title>Offset Mode</title>
            <meta name="description" content="Help fight climate change with Offset Mode." />
        </Helmet>
    )
}
export default MetaInfo;