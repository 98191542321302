import React from 'react';
import { Fade } from 'react-awesome-reveal';
import { gsap } from 'gsap';
import { useMount } from '@react-hooks-library/core';
import useInView from 'react-cool-inview';

import { ReactComponent as MonitorImg } from './i/svgs/graph.svg';
import { ReactComponent as CalculateImg } from './i/svgs/smoke-stacks.svg';
import { ReactComponent as SaveImg } from './i/svgs/trees.svg';

import './styles.scss';

let graphTL, smokeTL, treeTL, fadeTL;
let furthest = 0;

const SectionHowItWorks = () => {
    useMount(() => {
        graphTL = gsap.timeline({ paused: true });
        graphTL.add([
            gsap.from('#Graph .linegraph', { duration: 2, drawSVG: '0%' }),
            gsap.from([ '#Graph .bar1', '#Graph .bar2', '#Graph .bar3' ], {
                duration: 2,
                stagger: 0.4,
                drawSVG: '100% 100%'
            })
        ]);

        smokeTL = gsap.timeline({ paused: true });
        smokeTL.add([
            gsap.from('.stack1', { duration: 0.5, y: 80, ease: 'power2.Out' }),
            gsap.to('.smoke-cover1', { duration: 2, height: 0, ease: 'power2.inOut' })
        ]);
        smokeTL.add(
            [
                gsap.from('.stack2', { duration: 0.5, y: 80, ease: 'power2.Out' }),
                gsap.to('.smoke-cover2', { duration: 2, height: 0, ease: 'power2.inOut' })
            ],
            '-=1'
        );
        smokeTL.add(
            [
                gsap.from('.stack3', { duration: 0.5, y: 80, ease: 'power2.Out' }),
                gsap.to('.smoke-cover3', { duration: 2, height: 0, ease: 'power2.inOut' })
            ],
            '-=1'
        );

        treeTL = gsap.timeline({ paused: true });
        treeTL.add([
            gsap.from('.tree1', { duration: 1, scale: 0, transformOrigin: 'center bottom', ease: 'back.out(1.7)' }),
            gsap.from([ '.foliage-1 .bottom', '.foliage-1>.mid', '.foliage-1>.top' ], {
                duration: 0.7,
                scale: 0,
                transformOrigin: 'center bottom',
                stagger: 0.25
            })
        ]);
        treeTL.add(
            [
                gsap.from('.tree2', { duration: 1, scale: 0, transformOrigin: 'center bottom', ease: 'back.out(1.7)' }),
                gsap.from([ '.foliage-2>.bottom', '.foliage-2>.mid', '.foliage-2>.top' ], {
                    duration: 0.7,
                    scale: 0,
                    transformOrigin: 'center bottom',
                    stagger: 0.25
                })
            ],
            '-=0.75'
        );
        treeTL.add(
            [
                gsap.from('.tree3', { duration: 1, scale: 0, transformOrigin: 'center bottom', ease: 'back.out(1.7)' }),
                gsap.from([ '.foliage-3>.bottom', '.foliage-3>.mid', '.foliage-3>.top' ], {
                    duration: 0.7,
                    scale: 0,
                    transformOrigin: 'center bottom',
                    stagger: 0.25
                })
            ],
            '-=0.75'
        );

        fadeTL = gsap.timeline({
            paused: true
        });
        fadeTL.from('.how-it-works-1', {
            opacity: 0
        });
        fadeTL.call(() => {
            graphTL.play();
        });
        fadeTL.addLabel('works-1-visible');
        fadeTL.from('.how-it-works-2', {
            opacity: 0
        });
        fadeTL.call(() => {
            smokeTL.play();
        });
        fadeTL.addLabel('works-2-visible');
        fadeTL.from('.how-it-works-3', {
            opacity: 0
        });
        fadeTL.call(() => {
            treeTL.play();
        });
        fadeTL.addLabel('works-3-visible');
    });

    const doViewCheck = (level) => {
        if (level <= furthest) return;
        if (level === 1) {
            fadeTL.tweenTo('works-1-visible');
        } else if (level === 2) {
            fadeTL.tweenTo('works-2-visible');
        } else if (level === 3) {
            fadeTL.tweenTo('works-3-visible');
        }

        furthest = Math.min(level, 3);
    };

    const { observe: work1Observe } = useInView({
        onEnter: ({ unobserve }) => {
            unobserve();
            doViewCheck(1);
        },
        threshold: 1
    });
    const { observe: work2Observe } = useInView({
        onEnter: ({ unobserve }) => {
            unobserve();
            doViewCheck(2);
        },
        threshold: 1
    });
    const { observe: work3Observe } = useInView({
        onEnter: ({ unobserve }) => {
            unobserve();
            doViewCheck(3);
        },
        threshold: 1
    });

    return (
        <div className="section-block how-it-works">
            <Fade triggerOnce delay={200}>
                <h3 className="section-title">How it Works</h3>
            </Fade>
            <div className="how-it-works-wrapper">
                <div className="how-it-works-1 fade-works" ref={work1Observe}>
                    <MonitorImg />
                    <h4>Monitor Usage</h4>
                    <p>Offset Mode monitors your browsing, streaming, and downloading&nbsp;data.</p>
                </div>

                <div className="how-it-works-2 fade-works" ref={work2Observe}>
                    <CalculateImg />
                    <h4>Calculate Footprint</h4>
                    <p>It then calculates the amount of carbon your digital habits emit into the real&nbsp;world.</p>
                </div>

                <div className="how-it-works-3 fade-works" ref={work3Observe}>
                    <SaveImg />
                    <h4>Save the World</h4>
                    <p>
                        It then tells you how much of a donation to Tree Canada is needed to offset your
                        carbon&nbsp;footprint.
                    </p>
                </div>
            </div>
        </div>
    );
};
export default SectionHowItWorks;
