import React, {useState} from 'react';
//import Modal from '../../components/UI/Modal/Modal';
import useToggle from '../../hooks/useToggle';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import BGAnim from '../../components/BGAnim/BGAnim';
import { Fade } from "react-awesome-reveal";

import OffsetLogo from '../../assets/images/offset-logo@2x.png';
import ButtonDownload from '../../components/ButtonDownload/ButtonDownload';
import MetaInfo from '../../components/MetaInfo/MetaInfo';
import SectionWhyItMatters from '../../components/SectionWhyItMatters/SectionWhyItMatters';
import SectionHowItWorks from '../../components/SectionHowItWorks/SectionHowItWorks';
import SectionDigitalFootPrint from '../../components/SectionDigitalFootPrint/SectionDigitalFootprint';
import SectionTips from '../../components/SectionTips/SectionTips';
import SectionFaq from '../../components/SectionFaq/SectionFaq';
import Footer from '../../components/Footer/Footer';
// import SubheaderText from './Images/subheader-text.png';
// import DownloadButton from './Images/dl-button.png';

import './styles.scss';

const Home = (props) => {
    gsap.registerPlugin(ScrollTrigger);
    const {isMobile} = props;
    //const [showModal, setShowModal] = useState(false);
    const [isToggled, toggle] = useToggle(false);

    
    console.log('home props ', props);

    return (
        <>
        <MetaInfo {...props} />
        <div className="home">
            <BGAnim />
            <div className="section section-1 scroll-start">
                <div className="section-block block-header">
                    <img src={OffsetLogo} className="logo" alt="Offset logo" />
                    <Fade triggerOnce delay={400}>
                        <h1>Offset your<span>Digital Emissions</span>In Real-time</h1>
                    </Fade>
                </div>
                <div className="section-block block-subheader scroll-end">
                <Fade triggerOnce delay={200}>
                    <h2>Help fight climate change with <strong>Offset&nbsp;Mode</strong> — a digital tool that uses real-time data to calculate and offset your digital carbon footprint through a series of micro-donations.</h2>
                </Fade>
                    <ButtonDownload />
                </div>
            </div>
            <div className="section-wrapper">
                <div className="section section-2">
                    <SectionWhyItMatters />
                </div>

                <div className="section section-3">
                    <SectionHowItWorks />
                </div>

                <div className="section section-4">
                    <SectionDigitalFootPrint {...props} />
                </div>

                <div className="section section-5">
                    <SectionTips />
                </div>

                <div className="section section-6">
                    <SectionFaq />
                </div>
            </div>
            
            <div className="section footer">
                <Footer />
            </div>
        </div>
        </>
    )
}
export default Home;