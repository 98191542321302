import React from "react";
import { Fade } from 'react-awesome-reveal';

import ButtonDownload from '../ButtonDownload/ButtonDownload';

import "./styles.scss";

import screenShot from './i/screen.png';

const SectionDigitalFootPrint = (props) => {
    const {isMobile} = props;
    return (
        <div className="section-block section-digital-footprint">
            <div className="digital-footprint-wrapper">
              
                <div className="digital-footprint-content">
                    <Fade triggerOnce delay={200}>
                    <h3 className="section-title">Start offsetting your<br />Digital Footprint</h3>
                   
                    <p>
                    Add Offset Mode to Chrome start tracking your digital footprint — and learning how much of a donation to Tree Canada would be required to offset it.
                    <br /><br />
                    {isMobile ? 
                        "Offset Mode is not available for Mobile use. Please visit this website through the Chrome browser on your desktop." 
                        : 
                        "It's free to install and micro-donations can be made directly through Offset Mode. Depending where you live, and the type of energy your region uses, the cost to offset your digital footprint could be as little as a couple cents per day — a small price to pay to combat climate change."}
                    </p>
                  
                    </Fade>
                    {!isMobile ? 
                        <Fade direction="left" triggerOnce>
                            <ButtonDownload />
                        </Fade>
                      : ''
                    }
                </div>
               
               
            
                <Fade triggerOnce className="screenshot" direction="left" delay={100}>
                    <img src={screenShot} alt="screen" />
                </Fade> 
               
               
            </div>
           
        </div>
    )
};
export default SectionDigitalFootPrint;