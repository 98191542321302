import { Sprite, Stage } from '@inlet/react-pixi';
import { useWindowSize } from '@react-hook/window-size';
import { useEffect, useRef, useState } from 'react';

// import VideoLoopSprite from './VideoLoopSprite';

import SmogVideo from '../../assets/videos/smoke-stacks.mp4';
//import SmogVideo from '../Videos/smoke-stacks.mp4';
import TreesBG from '../../assets/images/trees.jpg';
//import TreesBG from '../Images/trees.jpg';
import BlockMask from '../BlockMask/BlockMask';
//import BlockMask from './BlockMask';

const imageWidth = 1222;
const imageHeight = 816;
const androidPadding = 150;

const BGAnim = () => {
    const [ width, height ] = useWindowSize();
    const [ paddedHeight, setPaddedHeight ] = useState(height + androidPadding);
    const blockMaskRef = useRef();
    const revealImageRef = useRef();

    useEffect(
        () => {
            if (blockMaskRef.current !== null && revealImageRef !== null) {
                revealImageRef.current.mask = blockMaskRef.current;
            }
        },
        [ blockMaskRef, revealImageRef ]
    );

    useEffect(
        () => {
            setPaddedHeight(height + androidPadding);
        },
        [ height ]
    );

    const getImageScale = () => {
        const widthRatio = width / imageWidth;
        const heightRatio = paddedHeight / imageHeight;

        if (widthRatio > heightRatio) {
            return widthRatio;
        } else {
            return heightRatio;
        }
    };

    return (
        <div className="bganim">
            <video src={SmogVideo} muted autoPlay controls={false} loop />
            <Stage width={width} height={paddedHeight} options={{ backgroundAlpha: 0 }}>
                {/* <VideoLoopSprite
                    source={SmogBG}
                    x={width * 0.5}
                    y={paddedHeight * 0.5}
                    scale={getImageScale()}
                    anchor={0.5}
                /> */}
                <Sprite
                    image={TreesBG}
                    x={width * 0.5}
                    y={paddedHeight * 0.5}
                    scale={getImageScale()}
                    anchor={0.5}
                    ref={revealImageRef}
                />
                <BlockMask
                    stageWidth={width}
                    stageHeight={paddedHeight}
                    maxBlockWidth={200}
                    minBlockCount={4}
                    ref={blockMaskRef}
                />
            </Stage>
        </div>
    );
};

export default BGAnim;
