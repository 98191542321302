import React from "react";
import Collapsible from 'react-collapsible';


import "./styles.scss";

const SectionFaq = () => {

    return (
        <div className="section-block section-faq">
            <h3 className="section-title">FAQ</h3>
            <div className="collapsible-wrapper">
                <Collapsible trigger='How do you calculate the emissions?'>
                    <p>
                        Offset Mode uses the IEA assumption of .23 kWh generated per GB transferred, then multiplies that by the carbon generated per kWh in the user's region — this number can vary greatly depending on how clean a region's power source is. We then assume a single $5 tree will offset 21 kg of CO2 to arrive at the donation needed to offset the user's online activity.
                    </p>
                </Collapsible>
                <Collapsible trigger='How much can I expect to donate?'>
                    <p>
                        Donations will vary greatly depending on both how much data you use and where in the world you’re located. This is because electricity generated from dirty sources can cause over 70x the emissions as electricity generated from clean sources. However, the average Canadian household might only expect to donate a few dollars a month — a small price to pay to completely offset your footprint.
                    </p>
                </Collapsible>
                <Collapsible trigger='Does Offset Mode track me or store my data?'>
                    <p>
                        Offset Mode does not store, track, or sell any user data.
                    </p>
                </Collapsible>
                <Collapsible trigger='What do you mean by "micro-donation"?'>
                    <p>
                        A micro-donation is a form of charitable donation that is small in the donated amount — in the case of Offset Mode this could mean as little as pennies per day, or as high as dollars per day. But when everyone acts together, these micro-donations can make a huge difference in the fight against climate change.
                    </p>
                </Collapsible>
                <Collapsible trigger="My donation amount isn't increasing. Is it working?">
                    <p>
                        Yes. A factor that can dramatically affect your donation is the electrical mix of your region. If you're in a region with clean electricity, generated from wind, solar, or hydro, your digital carbon footprint will be much smaller than Internet users in regions where electricity is generated from fossil fuels. But, if you keep using the Internet, eventually you'll see your donation amount tick up.
                    </p>
                </Collapsible>
                <Collapsible trigger='Does it automatically charge my credit card?'>
                    <p>
                        No. Offset Mode requires a user to opt in and go through payment forms to donate. If you're simply interested in seeing what your footprint is, Offset Mode also works as an educational tool.
                    </p>
                </Collapsible>
                <Collapsible trigger='What will my donation go towards?'>
                    <p>
                        Your donations will go towards Tree Canada and our carbon offsetting tree planting programs.
                    </p>
                </Collapsible>
                <Collapsible trigger='Does Offset Mode track me or store my data?'>
                    <p>
                        No. Offset Mode does not store, track, or sell any user data.
                    </p>
                </Collapsible>
                <Collapsible trigger='What will my donation go towards?'>
                    <p>
                        Your donations will go towards Tree Canada and our carbon offsetting tree planting programs.
                    </p>
                </Collapsible>
                <Collapsible trigger='My donation seems so small, will it really make a difference?'>
                    <p>
                        The individual's footprint may be small, but when you consider the 4.6 billion daily Internet users, the collective footprint is massive. If we all do our part, we can offset climate change, together.
                    </p>
                </Collapsible>
                <Collapsible trigger='Will I get a charitable tax receipt when I donate to Tree Canada?'>
                    <p>
                        Yes. You will automatically receive a tax receipt for income tax purposes when you make a donation of $25 or more. Please contact our development team directly with any questions.
                    </p>
                </Collapsible>
            </div>
        </div>
    )
};
export default SectionFaq;